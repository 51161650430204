import React, {Component} from 'react'
import Band from '../../images/TeachingBK.jpg'
import Resume from '../../images/Resume.pdf' 

const Content3 = () => {
    return(
      <div>
        <div className="green section-top darken-1">
          <section className="scrollspy no-autoinit" id="about">
          <div className="container section-bottom">     
          <h3 className="center">My resume can be found <a href={Resume} target="_blank" className="green-text resume text-darken-4">here</a></h3>
          </div>  

          <div className="container center section-bottom">
            <img className="responsive-img" src={Band} alt=""/>
          </div>
          </section>
        </div>    
      </div>
    )
  }


export default Content3

