import React from 'react'
import Courier from '../../images/projectTabs/Courier.png'
import FETracker from '../../images/projectTabs/FETracker2.png'
import MarioPlan from '../../images/projectTabs/MarioPlanReact.png'
import MQBossCalc from '../../images/projectTabs/MQBossCalc.png'
import Recipe from '../../images/projectTabs/RecipeConverter.png'
import Twitch from '../../images/projectTabs/TwitchRemake.png'
import CourierRemake from '../../images/projectTabs/CourierRemake.png'
import Organizer from '../../images/projectTabs/Organizer.png'
import UILibrary from '../../images/projectTabs/UI-Library.png'

const projects = [
  {
    src: Courier, //0
    name: "Courier Project",
    url: "http://courierwebservice.s3-website-us-west-2.amazonaws.com/home",
    description1: "This application is developed using SQL, C#, Entity Framework, ASP.NET, and Angular. I designed it while working as a full-stack application development intern at PeopleTech Group in Redmond, WA.",
    description2: "The idea was developed using experience from my time working as a courier. The company I worked for didn't have an online application for customers to place orders, which led me to develop one on my own. It is an educational project, and the first full-stack project I've developed. It is meant to show my ability to work on all aspects of a project, from the database all the way to the end user, from creation to completion.",
    description3: "It's not meant to be a project for production, but was instead used as an educational tool to apply things as I learned them. It gave me a way to use HTTP calls to the server, a way to use SQL queries to access database information, a way to display the incoming information in a user interface using Angular, a way to protect the data using Microsoft's Owin security system, and many other things."
  },
  {
    src: CourierRemake, //inserted 10/2/2020 //1
    name: "Courier Project Remake",
    url: "https://courierremake.firebaseapp.com/",
    description1: "This represents my ability to design web applications capable of providing effective business solutions, and is a remake of the courier project shown below.  While the original project took me weeks to develop as I learned every little element as I developed it, this one took an afternoon.",
    description2: "It uses HTML, CSS, JavaScript, and Materialize CSS in the front-end, and uses Firebase Firestore for back-end.  It uses all the same basic functionalities as the original project, and will be given the same amount of depth and detail as I continue developing it.",
    description3: "Fleshing this project out will be my next goal.  First and foremost, I will build it as a React application, and will use Bootstrap to gain experience with another CSS framework.  I want to add all of the depth of the original version and beyond, utilizing different tools I have learned since creating the previous version."
  },
  {
    src: FETracker, //2
    name: "Video Game Item Tracker",
    url: "http://davidharmonintropage.s3-us-west-2.amazonaws.com/projects/FETracker/FETracker.html",
    description1: `This item tracker was requested by a client for use in a video game randomizer.  
    I was given a deadline of two days, as the tracker would need to be used in a very short amount of time.  
    It was developed before I learned about CSS frameworks, so it only uses HTML, CSS, and JavaScript to perform tasks as requested by the user.`,

    description2: `This project had me in contact with the client multiple times a day during those two days, 
    and a few times since then to fine-tune things as necessary. I was given an idea, and asked to make it functional 
    and real. I presented sections of the project to the client as they were completed, and made corrections as requested, 
    until the application performed exactly as the client requested.`,

    description3: `The tracker allows the user to input short, predetermined letter combinations into the yellow input slots, which 
    then autofills the necessary slots in the other sections of the tracker. The JavaScript logic keeps track of what items belong where, 
    and what items can be used by different characters. It also includes mistake-correction measures, including the client-requested 'Whoopsie!' button.`
  },
  {
    src: MarioPlan, //3
    name: "React Blog Application",
    url: "https://marioplan-89019.firebaseapp.com/",
    description1: "This application was created as part of an online React course I found.  It uses Redux, Firebase/Firestore, and Materialize CSS to store new posts and new users in a Firebase database, and cloud functions to return information in real-time as a new post or user is created.",
    description2: "It was designed to use as many elements of a full-stack React application as possible.  It includes Firebase authentication, Links vs NavLinks, Redux reducers, Thunk to allow asynchronous commands, Firestore database creation and data retrieval, route guarding, Firestore security rules, cloud functions, reroutes, components, and more.",
    description3: "Having already learned HTML, CSS, and JavaScript, I found that having these frameworks available significantly simplifies both the process of creating applications, and styling them as well.  One of my projects I'll mess with when I have some free time is to go back and recreate the item tracker found above using Materialize, to see how much easier and better-looking I could have created it"
  },
  {
    src: Recipe, //4
    name: "Recipe Converter",
    url: "http://recipeconverter.s3-website-us-west-2.amazonaws.com/",
    description1: "With Coronavirus continuing its rampage across the United States, my wife and I have found ourselves cooking even more than we already had been. And while I enjoy the thought of using a recipe that makes 48 cookies, eating that many cookies is certainly not good for my health.",
    description2: "To remedy this, I created a simple application that allows for recipes to be reduced or enlarged, and shows the resulting quantity of ingredients which should be used. It allows recipes to be saved, loaded, and converted, and has logic to convert smaller measurements into larger ones (teaspoons to tablespoons, for example) and vice versa.",
    description3: "Now that I have seen the light and have used a CSS framework, my next plan is to return to this project and make it look significantly better.  I also plan on evolving it into a full-stack React application, and this will be my primary project to continue learning the ins and outs of React"
  },
  {
    src: Twitch, //5
    name: "Twitch Remake from Scratch",
    url: "http://davidharmonintropage.s3-us-west-2.amazonaws.com/projects/TwitchRemake/following.html",
    description1: "I wanted a way to practice my CSS skills, and decided to recreate a page from one of my favorite websites, Twitch.  I haven't copy/pasted anything from the Chrome Developer Tools, and I haven't used any CSS frameworks.  I wanted to practice the true fundamentals of CSS and recreate something professional with it.",
    description2: "It's not a complete project, just something I wanted to use to get some good practice on it, then move on to a bigger project.",
    description3: "I hope you like Bob Ross!"
  },
  {
    src: MQBossCalc, //6
    name: "Excel Visual Basic Program",
    description1: "This was a quick project I created for myself.  I wanted to learn how to make basic, effective Excel Applications, and created this to learn the fundamentals.",
    description2: "The buttons auto-populate the numbers as necessary, and color code others to help track important information as I'm using it.  The 'Load __' buttons change the look of the calculator for use depending on what information I need to see.",
    description3: ""
  },
  {
    src: Organizer, //inserted 10/27/2020 //7
    name: "Home Organizer",
    url: "https://lifeorganizer-3ecd5.web.app/",
    description1: "This is my best project to date.  It is designed using JavaScript, CSS, and HTML, and I am currently in the process of recreating it with React.  It was created out of necessity, and is an application my wife and I use daily, which makes me even more proud of its success.",
    description2: "It uses Firebase for authentication and Firestore for database storage.  The program itself centralizes multiple aspects of our lives, helping us keep recipes, shopping lists, and todo lists up to date from any device.  Weekend meal prep now includes finding recipes, inputting them, creating the shopping list from them, and having access to all of that information at the click of a button.",
    description3: "This application has been detailed to a point where I'm happy to show it off and offer it to friends to use.  I still have features I'd like to add to it, starting with some user options such as changing passwords and updating other account information.  Feel free to use it yourself, I hope it helps you too!"
  },
  {
    src: UILibrary, //inserted 10/27/2020 //8
    name: "UI Library",
    url: "https://uilibrary-9f0b7.firebaseapp.com/",
    description1: "This is a UI Library I'm passively designing.  I took the idea from a Javascript course, and continued developing new features of it.  The course provided some simple Javascript UI elements, and I wanted to add something of my own.",
    description2: "The standard scrollbar has a fair amount of customization options, but was missing some other options as well.  I wanted to be able to fully customize my own, so I made a few variations and designed all 4 of these from scratch.",
    description3: "The scrollbar on this projects page is one example.  I used various equations to map out the correct location of the thumb based on the amount of content that has been scrolled through, and likewise the correct location of the content as the thumb is clicked and dragged."
  },
]

export default projects;