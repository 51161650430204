import React, {Component} from 'react'
import Navbar from './Navbar'
import Content1 from './Content1'
import Footer from './Footer'
import M from  'materialize-css/dist/js/materialize.min.js';
import Scrollbar from './Scrollbar'

class Projects extends Component{
  
  componentDidMount(){
    window.scrollTo(0,0);
    M.AutoInit();
    document.querySelector('body').classList.add('hideScrollbar');
  }

  componentWillUnmount(){
    console.log('unmounted projects window');
  }

  render(){
    return(
      <div>
        <Navbar/>
        <Scrollbar/>
        <Content1/>
        <Footer/>
      </div>
    )
  }
}

export default Projects