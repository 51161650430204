import React from 'react'
import {Link} from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="page-footer grey darken-3">
      <div className="container">
        <div className="row center">
          <div className="col s12">
            <Link to="/"><h5>Back to Home</h5></Link>            
          </div>
        </div>
      </div>
      <div className="footer-copyright grey darken-4">
        <div className="container center-align">&copy; 2020 David Harmon</div>
      </div>
    </footer>
  )
}

export default Footer

