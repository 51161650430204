import React, {Component} from 'react'
import Navbar from './Navbar'
import Content1 from './Content1'
import Parallax1 from './Parallax'
import Parallax2 from './Parallax2'
import Content2 from './Content2'
import Content3 from './Content3'
import Footer from './Footer'
import M from  'materialize-css/dist/js/materialize.min.js';

class Home extends Component{

  componentDidMount(){
    window.scrollTo(0,0);
    M.AutoInit();
    let scrollspy = document.querySelector("#about");
    M.ScrollSpy.init(scrollspy, {scrollOffset: 0});
    document.querySelector('body').classList.remove('hideScrollbar');
  }

  render(){
    return(
      <div>
        <Navbar/>
        <Content1/>
        <Parallax1/>
        <Content2/>
        <Parallax2/>
        <Content3/>
        <Footer/>
      </div>
    )
  }
}

export default Home