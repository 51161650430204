import React from 'react'

const Footer = () => {
  return (
    <footer className="page-footer grey darken-3">
      <div className="container">
        <div className="row">
          <div className="col s12 l6">
            <h5>About Me</h5>
            <p>I earned my bachelors degree in music education in 2014, and taught
              band in San Antonio for two years before switching gears and training
              to be an application developer.  My online coursework earned me an internship
              with PeopleTech Group in Redmond, WA, where I developed my full-stack 
              application development skills.
            </p>
            <p>When Coronavirus ended my internship, I went home and continued learning new languages
              and developing new projects.  It was during this time that I solidified
              my preference as an application developer, and honed my skills by continuing coursework
              and developing applications, both for myself and for clients.
            </p>
            <p>I am ready to combine the skills I've learned with the work ethic I've developed over
              years of professional performance experience.  I am looking for a company to call home
              where I can be a valuable asset to help you reach even your most ambitious goals.
            </p>
          </div>
          <div className="col s12 l4 offset-l2">
            <h5 className="section-bottom-small">Connect</h5>
            <ul>
              <li class="grey-text text-lighten-3">
                <div className="btn-floating btn-small indigo darken-4 icon-spacer email-icon">
                <i className="far fa-envelope"></i></div>
                <span>Email: dj89harmon@gmail.com</span>
              </li>
              <li><br/></li>

              <li className="grey-text text-lighten-3">
                <div className="btn-floating btn-small indigo darken-4 icon-spacer email-icon">
                  <i className="material-icons">phone</i></div>
                  <span>Phone: (210) 789-3122</span>
              </li>

              <li><br></br></li>

              <li>
                <a href="https://www.linkedin.com/in/david-j-harmon" target="_blank" className="btn-floating btn-small indigo darken-4 icon-spacer">
                <i className="fab fa-linkedin"></i></a>
                <span>LinkedIn: <a href="https://www.linkedin.com/in/david-j-harmon" target="_blank"> https://www.linkedin.com/in/david-j-harmon</a></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright grey darken-4">
        <div className="container center-align">&copy; 2020 David Harmon</div>
      </div>
    </footer>
  )
}

export default Footer

