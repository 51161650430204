import React from 'react'
import Forest from '../../images/forest.jpg'

const Parallax2 = () => {
    return(
      <div className="parallax-container parallax-2">
        <div className="parallax" id="parallax2">
          <img src={Forest} alt="" className="responsive-img"/>
        </div>
      </div>
    )
}

export default Parallax2;