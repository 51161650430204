import React from 'react'
import '../../App.css'
import Projects from './Projects'

const Content1 = () => {

  const projects = Projects;

  console.log(projects);

  return(
    <div className="left indigo darken-4">
      <div className="row section-bottom section-top" style={{maxWidth: 85 + '%'}}>
        <div className="col s12 center">
          <h1 className="indigo-text text-lighten-4">Projects</h1>
        </div>
      </div>

      <div className="row">
          <h5 className="indigo-text text-lighten-4 left-10-px">*Click on a picture to access the project, and <span><a target="_blank" id="here" href="https://www.github.com/dj89harmon">here</a></span> to access my GitHub repository.</h5>
      </div>
      
      <div className="black divider practice"></div>
      <div className="row section-top section-bottom" style={{maxWidth: 85 + '%'}}>
        <div className="col s12 l4 offset-l1">
          <a href={projects[7].url} target="_blank"><img src={projects[7].src} alt="" className="responsive-img project-img"/></a>
        </div>
        <div className="col s12 l5 offset-l1 indigo-text text-lighten-4">
          <h2 className="text-align-top">{projects[7].name}</h2>
          <p>{projects[7].description1}</p>
          <p>{projects[7].description2}</p>
          <p>{projects[7].description3}</p>
        </div>
      </div>
      
      <div className="black divider practice"></div>
      <div className="row section-top section-bottom" style={{maxWidth: 85 + '%'}}>
        <div className="col s12 l4 offset-l1">
          <a href={projects[1].url} target="_blank"><img src={projects[1].src} alt="" className="responsive-img project-img"/></a>
        </div>
        <div className="col s12 l5 offset-l1 indigo-text text-lighten-4">
          <h2 className="text-align-top">{projects[1].name}</h2>
          <p>{projects[1].description1}</p>
          <p>{projects[1].description2}</p>
          <p>{projects[1].description3}</p>
        </div>
      </div>
      
      <div className="black divider practice"></div>
      <div className="row section-top section-bottom" style={{maxWidth: 85 + '%'}}>
        <div className="col s12 l4 offset-l1">
          <a href={projects[8].url} target="_blank"><img src={projects[8].src} alt="" className="responsive-img project-img"/></a>
        </div>
        <div className="col s12 l5 offset-l1 indigo-text text-lighten-4">
          <h2 className="text-align-top">{projects[8].name}</h2>
          <p>{projects[8].description1}</p>
          <p>{projects[8].description2}</p>
          <p>{projects[8].description3}</p>
        </div>
      </div>

      <div className="black divider"></div>
      <div className="row section-top section-bottom" style={{maxWidth: 85 + '%'}}>
        <div className="col s12 l4 offset-l1">
          <a href={projects[0].url} target="_blank"><img src={projects[0].src} alt="" className="responsive-img project-img"/></a>
        </div>
        <div className="col s12 l5 offset-l1 indigo-text text-lighten-4">
          <h2 className="text-align-top">{projects[0].name}</h2>
          <p>{projects[0].description1}</p>
          <p>{projects[0].description2}</p>
          <p>{projects[0].description3}</p>
        </div>
      </div>

      <div className="black divider"></div>
      <div className="row section-top section-bottom" style={{maxWidth: 85 + '%'}}>
        <div className="col s12 l4 offset-l1">
          <a href={projects[2].url} target="_blank"><img src={projects[2].src} alt="" className="responsive-img project-img"/></a>
        </div>
        <div className="col s12 l5 offset-l1 indigo-text text-lighten-4">
        <h2 className="text-align-top">{projects[2].name}</h2>
          <p>{projects[2].description1}</p>
          <p>{projects[2].description2}</p>
          <p>{projects[2].description3}</p>
        </div>
      </div>

      
      <div className="black divider"></div>
      <div className="row section-top section-bottom" style={{maxWidth: 85 + '%'}}>
        <div className="col s12 l4 offset-l1">
        <a href={projects[3].url} target="_blank"><img src={projects[3].src} alt="" className="responsive-img project-img"/></a>
        </div>
        <div className="col s12 l5 offset-l1 indigo-text text-lighten-4">
        <h2 className="text-align-top">{projects[3].name}</h2>
          <p>{projects[3].description1}</p>
          <p>{projects[3].description2}</p>
          <p>{projects[3].description3}</p>
        </div>
      </div>

      
      <div className="black divider"></div>
      <div className="row section-top section-bottom" style={{maxWidth: 85 + '%'}}>
        <div className="col s12 l4 offset-l1">
         <a href={projects[4].url} target="_blank"><img src={projects[4].src} alt="" className="responsive-img project-img"/></a>
        </div>
        <div className="col s12 l5 offset-l1 indigo-text text-lighten-4">
        <h2 className="text-align-top">{projects[4].name}</h2>
          <p>{projects[4].description1}</p>
          <p>{projects[4].description2}</p>
          <p>{projects[4].description3}</p>
        </div>
      </div>
      
      <div className="black divider"></div>
      <div className="row section-top section-bottom" style={{maxWidth: 85 + '%'}}>
        <div className="col s12 l4 offset-l1">
          <img src={projects[5].src} alt="" className="responsive-img materialboxed project-img"/>
        </div>
        <div className="col s12 l5 offset-l1 indigo-text text-lighten-4">
        <h2 className="text-align-top">{projects[5].name}</h2>
          <p>{projects[5].description1}</p>
          <p>{projects[5].description2}</p>
          <p>{projects[5].description3}</p>
        </div>
      </div>
    
    </div>
  )
}

export default Content1