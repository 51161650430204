import React from 'react'
import Mountains from '../../images/mountains.jpg'
import City from '../../images/city.jpg'
import Fish from '../../images/nature.jpg'
import '../../App.css'

const Content1 = () => {

  return(
    <div className="container section-top section-bottom">
      <div className="row section-top">
        <div className="col s12 l4">
          <img src={Fish} alt="" className="responsive-img materialboxed"/>
        </div>
        <div className="col s12 l6 offset-l1 hide-on-small-only">
          <h2 className="indigo-text text-darken-4 top-0">Effective Applications</h2>
          <p>Your clients want someone who can turn their biggest ideas into functional applications
            with a perfect user experience and a clean, flashy interface.  Not only am I well equipped 
            to achieve this level of expectation, I love the challenge it provides.
          </p>
          <p>I'm an expert in precision and detail.
              My professional demeanor and work ethic have helped me develop my skills as an application developer,
              and my expert understanding of the learning process, honed during my time spent as a teacher, allow me
              to learn new content efficiently and effectively.
          </p>
        </div>

        <div className="col s12 l6 offset-l1 hide-on-med-and-up">
          <h2 className="indigo-text text-darken-4">Effective Applications</h2>
          <p>Your clients want someone who can turn their biggest ideas into functional applications
            with a perfect user experience and a clean, flashy interface.  Not only am I well equipped 
            to achieve this level of expectation, I love the challenge it provides.
          </p>
          <p>I'm an expert in precision and detail.
              My professional demeanor and work ethic have helped me develop my skills as an application developer,
              and my expert understanding of the learning process, honed during my time spent as a teacher, allow me
              to learn new content efficiently and effectively.
          </p>
        </div>
      </div>

      <div className="row section-top">
        <div className="col s12 l4 push-l7 offset-l1">
          <img src={City} alt="" className="responsive-img materialboxed"/>
        </div>
        <div className="col s12 l6 pull-l5 right-align offset-l1 hide-on-small-only">
          <h2 className="indigo-text text-darken-4 top-0">Flexible Production</h2>
          <p>
            Your clients want someone who can give them exactly what they're asking for.  You need someone who can
            effectively communicate with the client and the project manager to ensure that every detail 
            is accurately communicated, then accurately delivered.
          </p>
          <p>
            I am entirely self-trained.  I've developed all of my projects through my own research.  Finding answers
            through research is a requirement for successful application developers.  I found courses, videos, and articles
            to solve questions as I come across them, and will continue to do so for the entirety of my career. 
          </p>
        </div>

        <div className="col s12 left-align hide-on-med-and-up">
          <h2 className="indigo-text text-darken-4">Flexible Production</h2>
          <p>
            Your clients want someone who can give them exactly what they're asking for.  You need someone who can
            effectively communicate with the client and the project manager to ensure that every detail 
            is accurately communicated, then accurately delivered.
          </p>
          <p>
            I am entirely self-trained.  I've developed all of my projects through my own research.  Finding answers
            through research is a requirement for successful application developers.  I found courses, videos, and articles
            to solve questions as I come across them, and will continue to do so for the entirety of my career. 
          </p>
        </div>

      </div>
    </div>
  )
}

export default Content1