import React from 'react'
import Mountains from '../../images/mountains.jpg'
import '../../App.css'
import pic1 from '../../images/projectTabs/MarioPlanReact.png'
import pic2 from '../../images/projectTabs/Courier.png'
import pic3 from '../../images/projectTabs/MQBossCalc.png'
import pic4 from '../../images/projectTabs/TwitchRemake.png'
import pic5 from '../../images/projectTabs/RecipeConverter.png'
import pic6 from '../../images/projectTabs/FETracker2.png'
import {NavLink} from 'react-router-dom'

const Content2 = () => {

  return(
  <div className="indigo darken-4 section-top">
    <div className="row section-top" id="projects">
      <div className="col s12 l6 offset-l3 indigo-text text-lighten-4">
        <p className="center flow-text">
          I have developed the following applications using:
        </p>
        <ul className="center">
          <li className="tab col s4">-HTML</li>
          <li className="tab col s4">-CSS</li>
          <li className="tab col s4">-JavaScript</li>

          <li className="tab col s4">-React</li>
          <li className="tab col s4">-Redux</li>
          <li className="tab col s4">-Firebase</li>

          <li className="tab col s4">-Angular</li>
          <li className="tab col s4">-Node.js</li>
          <li className="tab col s4">-C#</li>

          <li className="tab col s4">-ASP.NET</li>
          <li className="tab col s4">-AWS</li>
          <li className="tab col s4">-Entity Framework</li>

          <li className="tab col s4">-Materialize</li>
          <li className="tab col s4">-Bootstrap</li>
          <li className="tab col s4">-TailwindCSS</li>
          
          <li className="tab col s4">-Visual Basic</li>
          <li className="tab col s4">-SQL</li>
          <li className="tab col s4">-NoSQL</li>

        </ul>
      </div>
    </div>

    <div className="row section-bottom">
      <div className="col s12">
        <NavLink to="/projects"><h2 className="yellow-text center" id="ClickForProjects"><u>Click here to view completed and active projects</u></h2></NavLink>
      </div>
    </div>

    <div className="row">
      <div className="col s6 l4 offset-l1">
        <img src={pic1} className="responsive-img materialboxed"/>
      </div>
      <div className="col s6 l4 offset-l2">
        <img src={pic2} className="responsive-img materialboxed"/>
      </div>
    </div>

    <div className="carousel">
      <div className="carousel-item"><img src={pic6}/></div>
      <div className="carousel-item"><img src={pic3}/></div>
      <div className="carousel-item"><img src={pic4}/></div>
      <div className="carousel-item"><img src={pic1}/></div>
      <div className="carousel-item"><img src={pic2}/></div>
      <div className="carousel-item"><img src={pic5}/></div>
    </div>
    
  </div>
  )
}

export default Content2