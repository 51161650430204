import React, {Component} from 'react'
import Mountains from '../../images/stars.jpg'

const Parallax1 = () => {
    return(
      <div className="parallax-container">
        <div className="parallax" id="parallax1">
          <img src={Mountains} alt="" className="responsive-img"/>     
        </div>
      </div>
    )
}

export default Parallax1;