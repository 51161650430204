import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import Home from './components/home/Home'
import Projects from './components/projects/ProjectMain'
import 'materialize-css/dist/css/materialize.min.css'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route path='/projects' component={Projects}/>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
