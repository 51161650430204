import React, { Component } from 'react'


class Scrollbar extends Component{
  constructor(props){
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      top: 0,
      isDown: false
    }
  }
  
  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll);
    document.querySelector('.veil').addEventListener('mousedown', e => this.handleThumbMouseDown(e));
    window.addEventListener('mouseup', e => this.handleThumbMouseUp(e));
    window.addEventListener('mousemove', e => this.handleThumbMouseMove(e));
  }

  componentWillUnmount(){
    console.log('removing handleScroll()');
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () =>{

    const body = document.querySelector('body');
    const thumb = document.querySelector('.thumb');
    const veil = document.querySelector('.veil');
    const thumbHeight = 140;

    const pixels = (body.scrollHeight - window.innerHeight) - window.pageYOffset;
    const percentHeight = Math.abs(((pixels * 100) / (body.scrollHeight - window.innerHeight)) - 100);

    const position = (window.innerHeight - thumbHeight) *(percentHeight / 100);

    this.setState({
      top: position
    })

    thumb.style.top = this.state.top;
    veil.style.top = this.state.top;
  }

  handleThumbMouseDown = () => {
    this.setState({
      isDown: true
    })
  }

  handleThumbMouseUp = () => {
    this.setState({
      isDown: false
    })
  }

  handleThumbMouseMove = e => {
    if(!this.state.isDown) return;
    e.preventDefault();
    this.handleDrag(e);
  }

  handleDrag = e => {

    const body = document.querySelector('body');

    const thumbPos = e.clientY - 15;

    if(e.clientY <= 15){

      this.setState({
        top: 0
      })
      window.scrollTo(0,0);
    }
    else if(e.clientY >= window.innerHeight - 15){

      this.setState({
        top: window.innerHeight - this.thumbHeight
      })
      window.scrollTo(0, body.scrollHeight);
    } else {

      this.setState({
        top: thumbPos
      })

      const scrollPos = (body.scrollHeight - window.innerHeight) * (thumbPos / (window.innerHeight - 30));
      
      window.scrollTo(0, scrollPos);
    }
  }

  render(){
    return (
      <div className="scrollbar">
        <div style={{top: `${this.state.top}px`}} className="thumb"></div>
        <div style={{top: `${this.state.top}px`}} className="veil"></div>        
      </div>
    )
  }
}

export default Scrollbar

