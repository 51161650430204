import React from 'react'
import {Link} from 'react-router-dom'
import '../../App.css'

const Navbar = () => { 
      return(
        <header id="ProjectHeader">
            <nav className="nav-wrapper indigo darken-4">
              <div className="container">
                <div className="indigo-text text-lighten-4 brand-logo center">David Harmon</div>
                <a href="#" className="sidenav-trigger" data-target="mobile-menu">
                  <i className="material-icons">menu</i>
                </a>
      
                <ul className="left hide-on-med-and-down offset">
                  <li>
                    <a href="https://www.linkedin.com/in/david-j-harmon" target="_blank" className="btn-floating btn-small indigo darken-1 tooltipped" data-tooltip="LinkedIn">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.github.com/dj89harmon" target="_blank" className="btn-floating btn-small indigo darken-1 tooltipped" data-tooltip="GitHub">
                      <i className="fab fa-github"></i> 
                    </a>                
                  </li>
                  <li text-lighten-1><Link className="grey-text text-lighten-1" to="/">Home</Link></li>
                </ul>
      
              </div>
            </nav>
            <ul className="sidenav sidenav-close grey lighten-2 center" id="mobile-menu">
              <li className="section-top grey-text text-lighten-1"><Link to="/">Home</Link></li>
            </ul>
          </header>
          )
        }

export default Navbar